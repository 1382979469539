var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pos"},[_c('div',{staticClass:"pos_img_container"},[(_vm.isModalViewed)?_c('ModalView',{on:{"close-modal":function($event){_vm.isModalViewed = false}}},[_c('iframe',{attrs:{"src":"https://www.youtube.com/embed/IwkoYPOeTfs","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e(),_c('div',{staticClass:"up_btn mo",on:{"click":_vm.scrollTop}},[_c('img',{attrs:{"src":require("@/assets/image/product/pos/up_btn.png"),"alt":""}})]),_vm._m(0),_vm._m(1),_vm._m(2),_c('section',{staticClass:"sec4"},[_c('div',{staticClass:"inner"},[_c('h3',[_vm._v("실제 구동 영상")]),_c('h2',[_vm._v("윈윈포스, 이렇게 사용하세요!")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/assets/image/product/pos/movImg.png"),"alt":""},on:{"click":function($event){_vm.isModalViewed = true}}})])])]),_vm._m(3),_c('section',{staticClass:"sec6"},[_c('div',{staticClass:"inner"},[_c('h3',[_vm._v("실제 현장 설치 사례")]),_c('h2',[_vm._v("현장에서는 이렇게 사용하고 계십니다!")]),_c('carousel',{attrs:{"perPage":1,"loop":true,"autoplay":true,"autoplayTimeout":3500}},[_c('slide',[_c('img',{attrs:{"src":require("@/assets/image/product/pos/slide1.png"),"alt":""}})]),_c('slide',[_c('img',{attrs:{"src":require("@/assets/image/product/pos/slide2.png"),"alt":""}})]),_c('slide',[_c('img',{attrs:{"src":require("@/assets/image/product/pos/slide3.png"),"alt":""}})])],1)],1)]),_vm._m(4),_vm._m(5),_vm._m(6)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sec1"},[_c('div',{staticClass:"flex inner"},[_c('div',{staticClass:"box box1 flex"},[_c('div',{staticClass:"inner1"},[_c('h3',[_vm._v("국내 최초 농약사 맞춤형")]),_c('h2',[_vm._v("농약 유통 포스프로그램")]),_c('img',{attrs:{"src":require("@/assets/image/product/pos/newpos.png"),"alt":""}})])]),_c('div',{staticClass:"box box2 flex"},[_c('div',{staticClass:"desc"},[_c('p',[_vm._v("농약 판매 시, 작물 입력과 처방이력 확인 등")]),_c('p',[_vm._v("오직 농약사를 위한 맞춤형 기능부터")])]),_c('div',{staticClass:"desc"},[_c('p',[_vm._v("매출/매입 통계 자료 확인,")]),_c('p',[_vm._v("바코드 스캐너와 터치로 입력하는")]),_c('p',[_vm._v("포스 본연의 기능까지")])]),_c('div',{staticClass:"desc"},[_c('p',[_c('span',[_vm._v("이제는 농약사 맞춤형 유통 포스프로그램으로")])]),_c('p',[_vm._v("손쉽게 거래를 진행하고 기록하세요")])]),_c('div',{staticClass:"pos_btn"},[_c('a',{attrs:{"href":"https://yqf6a6vh560.typeform.com/to/c3cVeUq6","target":"_blank"}},[_vm._v("사전 신청하기")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sec2"},[_c('div',{staticClass:"inner"},[_c('img',{staticClass:"pc",attrs:{"src":require("@/assets/image/product/pos/com21.png"),"alt":""}}),_c('img',{staticClass:"mo",attrs:{"src":require("@/assets/image/product/pos/mo_com2.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sec3"},[_c('div',{staticClass:"inner"},[_c('h3',[_vm._v("농약사 전문 포스 프로그램")]),_c('h2',[_vm._v("윈윈포스는 가장 빠르고 편합니다")]),_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"box1"},[_c('h4',[_vm._v("PLS 맞춤형 포스")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/assets/image/product/pos/sec_img1.png"),"alt":""}})]),_c('div',{staticClass:"sub_tit"},[_c('p',[_vm._v("농약판매기록 전송을 위해")]),_c('p',[_vm._v("작물 입력 & 농진청 전송 기능 제공")]),_c('p',{staticClass:"ps"},[_vm._v("농진청 전송기능은 상반기 업데이트 예정")])])]),_c('div',{staticClass:"box2"},[_c('h4',[_vm._v("기존 프로그램과 연동")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/assets/image/product/pos/sec_img2.png"),"alt":""}})]),_c('div',{staticClass:"sub_tit"},[_c('p',[_vm._v("기존 우성 프로그램 뿐만 아니라")]),_c('p',[_vm._v("전용 어플까지 데이터 자동 연동")])])]),_c('div',{staticClass:"box3"},[_c('h4',[_vm._v("터치와 바코드로 쉽게")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/assets/image/product/pos/sec_img3.png"),"alt":""}})]),_c('div',{staticClass:"sub_tit"},[_c('p',[_vm._v("키보드 입력할 필요 없이,")]),_c('p',[_vm._v("화면 터치로 결제를 진행하세요")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sec5"},[_c('div',{staticClass:"inner"},[_c('h3',[_vm._v("복잡한 여러가지 기능 NO!")]),_c('h2',[_vm._v(" 정말 판매에 필요한 기능만 "),_c('span',[_vm._v("쏙")]),_vm._v(" 집어넣었습니다. ")]),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"row"},[_c('li',[_vm._v("결제기능 지원")]),_c('li',[_vm._v("고객/품목 등록, 관리")]),_c('li',[_vm._v("농진청 전송기능(예정)")])]),_c('ul',{staticClass:"row"},[_c('li',[_vm._v("매입/매출 통계")]),_c('li',[_vm._v("병해충 도감 (예정)")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sec7 pc"},[_c('div',{staticClass:"inner"},[_c('h3',[_vm._v("간단 한 사용 절차")]),_c('h2',[_vm._v("가장 효율적인 절차로 거래를 진행하세요")]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":require("@/assets/image/product/pos/pic5.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sec8 pc"},[_c('div',{staticClass:"inner flex"},[_c('div',{staticClass:"content1 content"},[_c('img',{attrs:{"src":require("@/assets/image/product/pos/last_pic.png"),"alt":""}})]),_c('div',{staticClass:"content2 content"},[_c('div',{staticClass:"head_tit"},[_c('p',[_c('span',[_vm._v("고객센터")]),_vm._v(" (월~금, 오전 9시~오후 6시) ")]),_c('h6',[_vm._v("1544-6301")])]),_c('div',{staticClass:"sub_tit"},[_c('p',[_vm._v("제품 관련 문의사항은 고객센터로 전화주세요.")]),_c('p',[_vm._v("친절하게 응답해드리겠습니다.")])]),_c('div',{staticClass:"pos_btn"},[_c('a',{attrs:{"href":"https://yqf6a6vh560.typeform.com/to/c3cVeUq6","target":"_blank"}},[_vm._v("사전 신청하기")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sec8_mo mo"},[_c('div',{staticClass:"inner flex"},[_c('div',{staticClass:"content2 content"},[_c('div',{staticClass:"head_tit"},[_c('p',[_c('span',[_vm._v("고객센터")]),_vm._v(" (월~금, 오전 9시~오후 6시) ")]),_c('h6',[_vm._v("1544-6301")])]),_c('div',{staticClass:"sub_tit"},[_c('p',[_vm._v("제품 관련 문의사항은 고객센터로 전화주세요.")]),_c('p',[_vm._v("친절하게 응답해드리겠습니다.")])]),_c('div',{staticClass:"content1 content"},[_c('img',{attrs:{"src":require("@/assets/image/product/pos/last_pic.png"),"alt":""}})]),_c('div',{staticClass:"pos_btn"},[_c('a',{attrs:{"href":"https://yqf6a6vh560.typeform.com/to/c3cVeUq6","target":"_blank"}},[_vm._v("사전 신청하기")])])])])])
}]

export { render, staticRenderFns }