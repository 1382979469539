<template>
  <div class="modal">
    <div class="overlay" @click="$emit('close-modal')"></div>
    <div class="modal-card">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
/* Modal */
.modal,
.overlay {
  z-index: 10;

  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.overlay {
  opacity: 0.5;
  background-color: black;
}
.modal-card {
  position: relative;
  width: 900px;
  height: 600px;
  margin: auto;
  margin-top: 150px;
  background-color: white;
  /* min-height: 500px; */
  z-index: 10;
  opacity: 1;
}
iframe {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .modal-card {
    margin-top: 100px;
    width: 90%;
    height: 300px;
  }
}
</style>