<template>
    <div class='inner banner inner_2'>
        <div class='wrap'>
            <div class="imgs">
                <img v-if="product == 'pro'" src="@/assets/image/product/pro.png" />
                <img v-else-if="product == 'pos'" src="@/assets/image/product/pos.png" />
                <img v-else-if="product == 'plus'" src="@/assets/image/product/plus.png" />
            </div>

            <div class='text_area'>
                <h3 v-if="product == 'pro'" v-html="pro.tit"></h3>
                <h3 v-else-if="product == 'pos'" v-html="pos.tit"></h3>
                <h3 v-else-if="product == 'plus'" v-html="plus.tit"></h3>

                <h2 v-if="product == 'pro'">Win-Win Pro</h2>
                <h2 v-else-if="product == 'pos'">Win-Win pos</h2>
                <h2 v-else-if="product == 'plus'">Win-Win Pro 결제연동</h2>

                <p v-if="product == 'pro'" v-html="pro.desc"></p>
                <p v-if="product == 'pos'" v-html="pos.desc"></p>
                <p v-if="product == 'plus'" v-html="plus.desc"></p>

                <router-link v-if="btn" tag='div' :to="'/product/'+product" class='btn'>자세히 보기</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['product','btn'],
    data(){
        return{
            pro:{
                tit:'작물 유통 프로그램의 기준!!',
                subTit:'Win-Win Pro',
                desc:'복잡하고 어려워진 농약판매 우성소프트가 해결해 드리겠습니다.'
            },
            pos:{
                tit:'국내 최초 농약 유통 포스 프로그램!!',
                subTit:'Win-Win 포스',
                desc:'복잡하고 어려워진 농약판매 우성소프트가 해결해 드리겠습니다.'
            },
            plus:{
                tit:'편리함에 편리함을 더하여',
                subTit:'Win-Win Pro 결제 연동 시스템',
                desc:'프로그램에서 카드(현금영수증) 발행 이력(승인/취소/조회)을 한 눈에! 개인정보 이용동의서, 미수금 확인서 등의 서명관리를 전자싸인으로 보다 편리하게!'
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/define.scss';

div.inner_2,.inner_3{
    box-sizing:border-box;
    background-size: cover;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div.wrap{
        display: flex;
        align-content: center;
    }
}

div.inner_2.banner {
    background-image: url(../../assets/image/product/pos_banner_bg.jpg);
    div.btn {
        background-color: $PointColor;
        color: #fff;
        cursor: pointer;
        border-radius: 3px;
    }
    div.imgs{
        flex: 1;
    }
    img {
        max-width: 350px;
        @media (max-width:1200px) {
            max-width: 300px;
        }
        @media (max-width:1024px) {
            width: 100%;
            position: absolute;
            opacity: 0.5;
        }
    }
    div.text_area {
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;
        margin-left: 50px;
        @media (max-width:1200px) {
            margin-left: 20px;
        }
        @media (max-width:1024px) {
            width: 100%;
            margin: 0;
            background-color: rgba($color: #fff, $alpha: 0.5);
            text-align: center;
            padding: 50px 0px;
        }
        h3 {
            font-size: 1.556rem;
            color: #444;
            span {
                text-decoration: underline;
            }
        }
        h2 {
            color: #444;
            font-weight: bold;
            font-size: 2.556rem;
        }
        p {
            text-align: left;
            margin-top: 40px;
            color: IndianRed;
            font-weight: bold;
            font-size: 1.35rem;
            top: 0;
            @media (max-width:1024px) {
                text-align: center;
                
            }
        }
    }
}
</style>