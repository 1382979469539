<template>
  <div class="pos">
    <div class="pos_img_container">
      <ModalView v-if="isModalViewed" @close-modal="isModalViewed = false">
        <iframe
          src="https://www.youtube.com/embed/IwkoYPOeTfs"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </ModalView>
      <!-- <div class="gap_box"></div> -->
      <div class="up_btn mo" @click="scrollTop">
        <img src="@/assets/image/product/pos/up_btn.png" alt />
      </div>
      <section class="sec1">
        <div class="flex inner">
          <div class="box box1 flex">
            <div class="inner1">
              <h3>국내 최초 농약사 맞춤형</h3>
              <h2>농약 유통 포스프로그램</h2>
              <img src="@/assets/image/product/pos/newpos.png" alt />
            </div>
          </div>
          <div class="box box2 flex">
            <div class="desc">
              <p>농약 판매 시, 작물 입력과 처방이력 확인 등</p>
              <p>오직 농약사를 위한 맞춤형 기능부터</p>
            </div>
            <div class="desc">
              <p>매출/매입 통계 자료 확인,</p>
              <p>바코드 스캐너와 터치로 입력하는</p>
              <p>포스 본연의 기능까지</p>
            </div>
            <div class="desc">
              <p>
                <span>이제는 농약사 맞춤형 유통 포스프로그램으로</span>
              </p>
              <p>손쉽게 거래를 진행하고 기록하세요</p>
            </div>
            <div class="pos_btn">
              <a href="https://yqf6a6vh560.typeform.com/to/c3cVeUq6" target="_blank">사전 신청하기</a>
            </div>
          </div>
        </div>
      </section>
      <section class="sec2">
        <div class="inner">
          <img class="pc" src="@/assets/image/product/pos/com21.png" alt />
          <img class="mo" src="@/assets/image/product/pos/mo_com2.png" alt />
        </div>
      </section>

      <section class="sec3">
        <div class="inner">
          <h3>농약사 전문 포스 프로그램</h3>
          <h2>윈윈포스는 가장 빠르고 편합니다</h2>
          <div class="content flex">
            <div class="box1">
              <h4>PLS 맞춤형 포스</h4>
              <div class="img_box">
                <img src="@/assets/image/product/pos/sec_img1.png" alt />
              </div>
              <div class="sub_tit">
                <p>농약판매기록 전송을 위해</p>
                <p>작물 입력 & 농진청 전송 기능 제공</p>
                <p class="ps">농진청 전송기능은 상반기 업데이트 예정</p>
              </div>
            </div>

            <div class="box2">
              <h4>기존 프로그램과 연동</h4>
              <div class="img_box">
                <img src="@/assets/image/product/pos/sec_img2.png" alt />
              </div>
              <div class="sub_tit">
                <p>기존 우성 프로그램 뿐만 아니라</p>
                <p>전용 어플까지 데이터 자동 연동</p>
              </div>
            </div>

            <div class="box3">
              <h4>터치와 바코드로 쉽게</h4>
              <div class="img_box">
                <img src="@/assets/image/product/pos/sec_img3.png" alt />
              </div>
              <div class="sub_tit">
                <p>키보드 입력할 필요 없이,</p>
                <p>화면 터치로 결제를 진행하세요</p>
              </div>
            </div>
          </div>
          <!-- <img src="@/assets/image/product/pos/com31.png" alt /> -->
        </div>
      </section>

      <section class="sec4">
        <div class="inner">
          <h3>실제 구동 영상</h3>
          <h2>윈윈포스, 이렇게 사용하세요!</h2>
          <div class="img_box">
            <img src="@/assets/image/product/pos/movImg.png" alt @click="isModalViewed = true" />
          </div>
        </div>
      </section>

      <section class="sec5">
        <div class="inner">
          <h3>복잡한 여러가지 기능 NO!</h3>
          <h2>
            정말 판매에 필요한 기능만
            <span>쏙</span> 집어넣었습니다.
          </h2>
          <div class="content">
            <ul class="row">
              <li>결제기능 지원</li>
              <li>고객/품목 등록, 관리</li>
              <li>농진청 전송기능(예정)</li>
            </ul>
            <ul class="row">
              <li>매입/매출 통계</li>
              <li>병해충 도감 (예정)</li>
            </ul>
          </div>
          <!-- <img src="@/assets/image/product/pos/com51.png" alt /> -->
        </div>
      </section>
      <section class="sec6">
        <div class="inner">
          <h3>실제 현장 설치 사례</h3>
          <h2>현장에서는 이렇게 사용하고 계십니다!</h2>
          <carousel :perPage="1" :loop="true" :autoplay="true" :autoplayTimeout="3500">
            <slide>
              <img src="@/assets/image/product/pos/slide1.png" alt />
            </slide>
            <slide>
              <img src="@/assets/image/product/pos/slide2.png" alt />
            </slide>
            <slide>
              <img src="@/assets/image/product/pos/slide3.png" alt />
            </slide>
          </carousel>
        </div>
      </section>

      <section class="sec7 pc">
        <div class="inner">
          <h3>간단 한 사용 절차</h3>
          <h2>가장 효율적인 절차로 거래를 진행하세요</h2>
          <div class="img_box">
            <img src="@/assets/image/product/pos/pic5.png" alt />
          </div>
        </div>
      </section>

      <section class="sec8 pc">
        <div class="inner flex">
          <div class="content1 content">
            <img src="@/assets/image/product/pos/last_pic.png" alt />
          </div>
          <div class="content2 content">
            <div class="head_tit">
              <p>
                <span>고객센터</span> (월~금, 오전 9시~오후 6시)
              </p>
              <h6>1544-6301</h6>
            </div>
            <div class="sub_tit">
              <p>제품 관련 문의사항은 고객센터로 전화주세요.</p>
              <p>친절하게 응답해드리겠습니다.</p>
            </div>
            <div class="pos_btn">
              <a href="https://yqf6a6vh560.typeform.com/to/c3cVeUq6" target="_blank">사전 신청하기</a>
            </div>
          </div>
        </div>
      </section>
      <section class="sec8_mo mo">
        <div class="inner flex">
          <div class="content2 content">
            <div class="head_tit">
              <p>
                <span>고객센터</span> (월~금, 오전 9시~오후 6시)
              </p>
              <h6>1544-6301</h6>
            </div>
            <div class="sub_tit">
              <p>제품 관련 문의사항은 고객센터로 전화주세요.</p>
              <p>친절하게 응답해드리겠습니다.</p>
            </div>
            <div class="content1 content">
              <img src="@/assets/image/product/pos/last_pic.png" alt />
            </div>
            <div class="pos_btn">
              <a href="https://yqf6a6vh560.typeform.com/to/c3cVeUq6" target="_blank">사전 신청하기</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ProBanner from "@/components/banner/banner-pro";
import Contact from "@/components/common/contact.vue";
import ModalView from "@/components/modal/ModalView.vue";
import Content from "@/components/modal/Content.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
    ProBanner,
    Contact,
    ModalView,
    Content,
  },
  data() {
    return {
      isModalViewed: false,
      scrollPosition: null,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 100);
    },
    // updateScroll() {
    //   this.scrollPosition = window.scrollY;
    //   if (window.scrollY >= 600) {
    //     this.app600 = true;
    //   } else {
    //     this.app600 = false;
    //   }
    // },
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/mixin.scss";
@import "./common.scss";
@import "./pos.scss";
</style>